import { useEffect, useCallback, useState } from 'react'

import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

import api from 'api'

import Circle from 'components/markup/loading/Circle'
import reactHTMLParser from 'html-react-parser';

import moment from 'moment'

const RecentStaffCommunication = ({matter, viewing_user}) => {

    const [ err, setErr ] = useState(false)
    const [ data, setData ] = useState(null)

    const fetchData = useCallback(async () => {
        if(matter._id) {
            const result = await api.matters.communication(matter._id)
            if(!result.success) return setErr(true)

            return setData(result.data)
        }
    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <Circle />

    return (
        <div>
            <Card >
                <CardHeader>
                    <CardTitle className="mb-0">Recent Notes & Communication</CardTitle>
                </CardHeader>

                <CardBody style={{maxHeight: 300, overflow: 'auto'}} className='comment-container card mb-0 bg-secondary'>
                    {data.notes.map(n => {
                        const isMyLastNote = data.myLastNote && data.myLastNote._id === n._id
                        const isMyNote = n.user && n.user._id === viewing_user._id;

                        return (
                            <div key={n._id} className={`${!isMyNote ? 'pl-5' : ''}`}>
                                <div 
                                    className={`my-2 border rounded bg-white`}
                                    style={{overflow: 'hidden'}}
                                >
                                    <div 
                                        className={`px-3 py-2 ${isMyLastNote ? 'bg-gradient-success text-white' : ''}`}
                                    >
                                        <b className='text-capitalize'>{isMyLastNote ? 'My Last Note:' : n.user.display_name}</b>
                                    </div>
                                    
                                    <div className='px-3 py-2 border-top border-bottom'>{n.value ? reactHTMLParser(n.value) : '--------'}</div>
                                    <div className='px-3 py-2'>{moment.unix(n.created_at).format('MM/DD/YYYY h:mm A')}</div>
                                </div>
                            </div>
                            
                        )
                    })}
                </CardBody>
            </Card>
        </div>
    )

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(RecentStaffCommunication);
