import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, CardBody, CardFooter } from "reactstrap";

import formatText from 'utils/formatText';
import moment from 'moment';

import reactHTMLParser from 'html-react-parser';
import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A';

import CallContactBadge from 'components/system/CallContact/Badge'

const ContactsOverview = ({contact, matters, lastNote}) => {
  
    return (

        <>

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Contact Info</CardTitle>
            </CardHeader>

            <CardHeader className="p-0" id="archk-tutorial-contacts-view-info">
                <div className="table-responsive">

                    <table className="table">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th className="text-right">Created</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>

                                <td className="text-capitalize td-pre">
                                    {contact.display_name ? (
                                        <div>
                                            {contact.display_name}
                                        </div>
                                    ) : (
                                        <span className="text-warning font-weight-bold">Name Not Given</span>
                                    )}
                                </td>

                                <td className="td-pre">
                                    <i className="fas fa-envelope mr-2 text-success " /> 
                                    {contact.email ? contact.email : '-'}
                                </td>

                                <td className="td-pre">

                                    {contact.phone ? (
                                        <CallContactBadge contact={contact._id} phone={contact.phone} color="success">
                                            <span className="cursor-pointer">{formatText(contact.phone).phone()}</span>
                                        </CallContactBadge>
                                    ) : (
                                        <span><i className="fas fa-mobile mr-2 text-success " /> -</span>
                                    )}
                                   
                                </td>


                                <td className="text-right td-pre">
                                    {moment.unix(contact.created_at).format('MM/DD/YYYY h:mm A')}
                                </td>

                            </tr>
                        </tbody>

                    </table>
                    <table className="table">

                        <thead>
                            <tr>
                                <th>Address Line 1</th>
                                <th>Address Line 2</th>
                                <th>City</th>
                                <th>State</th>
                                <th className="text-right">Zip</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>

                                <td className="text-capitalize td-pre">
                                    {contact.address_line_1 ? contact.address_line_1 : '-'}
                                </td>
                                <td className="text-capitalize td-pre">
                                    {contact.address_line_2 ? contact.address_line_2 : '-'}
                                </td>
                                <td className="text-capitalize td-pre">
                                    {contact.city ? contact.city : '-'}
                                </td>
                                <td className="text-capitalize td-pre">
                                    {contact.state ? contact.state : '-'}
                                </td>
                                <td className="text-right td-pre">
                                    {contact.postal_code ? contact.postal_code : '-'}
                                </td>

                            </tr>
                        </tbody>

                    </table>

                    
                </div>
            </CardHeader>
      
        </Card>

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Last Note</CardTitle>
            </CardHeader>

            <CardBody>
                {lastNote && lastNote.value ? reactHTMLParser(lastNote.value) : 'No Recent Notes Found'}
            </CardBody>

            <CardFooter className="text-right">
                {lastNote ? (
                    <div> 
                        {lastNote.user ? <span><ObjectFinder collection="users" _id={lastNote.user} /> - </span> : ''}
                        {moment.unix(lastNote.created_at).format('MM/DD/YYYY h:mm A')}
                    </div>
                ) : 'No Recent Notes Found'}
            </CardFooter>
        </Card>

        <Card>

            <CardHeader>
                    <CardTitle className="mb-0">Associated Matters</CardTitle>
                </CardHeader>

                <CardHeader className="p-0" id="archk-tutorial-contacts-view-info">
                    <div className="table-responsive">

                        <table className="table">

                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Division</th>
                                    <th>Workflow</th>
                                    <th>Current Step</th>
                                    <th className='text-right'>Created</th>
                                    <th className="text-right">Step Last Moved</th>
                                </tr>
                            </thead>

                            <tbody>
                               {matters.length ? matters.map(m => (
                                <tr key={m._id}>
                                    <td><A href={`/matters/${m.id}`}>{m.name}</A></td>
                                    <td><ObjectFinder collection="divisions" _id={m.division} /></td>
                                    <td><ObjectFinder collection="workflows" _id={m.workflow} /></td>
                                    <td>{m.current_step_name}</td>
                                    <td className='text-right'>{moment.unix(m.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                    <td className='text-right'>{moment.unix(m.current_step_start).format('MM/DD/YYYY h:mm A')}</td>
                                </tr>
                               )) : (
                                <tr>
                                    <td>No Matters Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                               )}
                            </tbody>

                        </table>

                        
                    </div>
                </CardHeader>
        
            </Card>

        </>

    );

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsOverview);