import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import ConfirmationModal from 'components/functional/modals/Confirmation';

import api from 'api';

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

import SearchProperty from '../../ScheduleAB/Search';
import SelectExemption from './SelectExemption';

const baseState = {
    bk_property                     : '',
    category                        : '',

    comment                         : '',
    limit                           : 0,
    amount                          : 0,

    amount_unknown                  : false,
    amount_is_percent               : false,
    check_100_percent_fmv           : false,

    print_text                      : '',
    print_amount                    : '',
    print_override                  : false,

    homestead_exemption             : false,
    homestead_acquired_1215_days    : false,
}

const ModalExemptionsEdit = ({showModal, toggleModal, config, onSuccess, exemption_id, matter}) => {

    const [err, setErr] = useState(false)
    const [showCategories, setShowCategories] = useState(exemption_id ? false : true)
    const [property, setProperty] = useState(null)
    const [exemption, setExemption] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const state = matter.parties.some(p => p.contact && p.contact.state === 'OH') ? 'OH' : 'MI';
    const lienValue = property && property.lien_value ? property.lien_value : 0

    let exemptions = config.exemptions[state].concat(config.exemptions.FEDERAL).filter(e => e.in_use)
    let selected = exemption ? exemptions.find(c => c.id === exemption.category) : null

    let claimValue = 0;

    if(property && property.current_value && exemption &&  exemption.amount && exemption.amount_is_percent) {
        claimValue = (property.current_value * exemption.amount) / 100
    } else if(exemption && exemption.amount) {
        claimValue = exemption.amount
    }

    const equityValue = property ? property.current_value - lienValue : 0

    const onInputChange = useCallback((field, val) => {
        const _exemption = JSON.parse(JSON.stringify(exemption));
        _exemption[field] = val;
        setExemption(_exemption)
    }, [exemption])
    
    const onSetCategory = useCallback((cat) => {
        const _exemption = JSON.parse(JSON.stringify(exemption));
        _exemption.category = cat.id;
        _exemption.limit = cat.limit;

        setShowCategories(false)
        setExemption(_exemption)
    }, [exemption])

    const onSetProperty = useCallback(async (obj) => {
        onInputChange('bk_property', obj ? obj.value : '')
        if(!obj) return setProperty(null)
        const result = await api.modules.bk.properties.findById(obj.value)
        if(!result.data) return toast.info(`Could not associate property, please try again.`)
        setProperty(result.data)
    }, [onInputChange])


    const onSave = useCallback(async () => {
        if(!exemption.bk_property) return toast.info(`A property must be set before updating a exemption`);

        const params = {
            ...exemption,
            matter: matter._id,
            limit: exemption.limit ? parseFloat(exemption.limit) : 0,
            amount: exemption.amount ? parseFloat(exemption.amount) : 0,            
        }

        let result;

        if(exemption_id) {
            result = await api.modules.bk.exemptions.update(exemption._id, params)
        } else {
            result = await api.modules.bk.exemptions.create(params)
        }

        

        if(!result.success) return toast.error(`Something went wrong updating this exemption, please refresh your page`)
        
        toast.success(`Exemption updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [exemption, exemption_id, matter._id, onSuccess, toggleModal])
  
    const onDelete = useCallback(async () => {
        if(!exemption.name) return toast.info(`A name must be set before updating a exemption`);

        const result = await api.modules.bk.exemptions.delete(exemption._id)

        if(!result.success) return toast.error(`Something went wrong deleting this exemption, please refresh your page`)
        
        toast.success(`Exemption deleted successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [exemption, onSuccess, toggleModal])

    const fetchData = useCallback(async () => {
        if(showModal) {
            if(exemption_id) {
                const result = await api.modules.bk.exemptions.findById(exemption_id);
                if(!result.success) return setErr(true);
                setExemption(result.data)

                if(result.data.bk_property) {
                    const result2 = await api.modules.bk.properties.findById(result.data.bk_property)
                    if(!result2.data) return toast.info(`Could not associate property, please try again.`)
                    setProperty(result2.data)
                }
                
            } else {
                setExemption(baseState)
            }
           
        } else {
            setErr(false)
        }
    }, [exemption_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Exemption</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this exemption, please refresh your page.</div>
                </div>
            ) : !exemption ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                        
                        {showCategories ? (
                            <div className="modal-body border-bottom">
                                <SelectExemption onSetCategory={onSetCategory} selected={selected} exemptions={exemptions} />
                            </div>
                        ) : (
                            <div>

                                {selected ? (
                                    <div className="modal-body border-bottom">
                                        <div className='my-3 border px-4 py-3 bg-dark text-white text-uppercase cursor-pointer' onClick={() => setShowCategories(true)}>
                                            <p className='text-m mb-0 font-weight-bold'><b><i className="fas fa-edit mr-2" /> SELECTED:</b> {selected.description} - {selected.law}</p>
                                        </div>
                                    </div>
                                ) : ''}

                                <div className="modal-body border-bottom">

                                    <SearchProperty 
                                        title="Associated Property"
                                        value={exemption.bk_property}
                                        onChange={(obj) => onSetProperty(obj)}
                                    />

                                    <FormGroup>
                                        <label className='form-control-label'>Law Comment (Optional)</label>
                                        <Input 
                                            type="text"
                                            value={exemption.comment}
                                            onChange={(e) => onInputChange('comment', e.target.value)}
                                        />
                                    </FormGroup>

                                </div>

                                {selected ? (
                                    <div className="modal-body bg-secondary border-bottom">

                                        <p className='mb-0'>Exemption Limits:</p>
    
                                        <Row>
                                            <Col md={6}>
                                                <p className='text-sm mb-0'>
                                                    Individual: {selected.individual_limit ? formatCurrency(selected.individual_limit) : 'Unlimited'}
                                                </p>
                                            </Col>
                                            <Col md={6}>
                                                <p className='text-sm mb-0'>
                                                    Joint: {selected.joint_limit ? formatCurrency(selected.joint_limit) : 'Unlimited'}
                                                </p>
                                            </Col>
                                        </Row>
    
                                    </div>
                                ) : ''}
                               

                                <div className="modal-body  border-bottom">
                                    <Row>
                                        <Col md={6}>

                                        {property ? (
                                            <div>
                                                <p className='mb-2'>Current Value: {formatCurrency(property.current_value)}</p>
                                            </div>
                                        ) : (
                                            <p className='text-sm mb-0'>No Property Selected</p>
                                        )}

                                            <FormGroup>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="archk-check_100_percent_fmv"
                                                        type="checkbox"
                                                        checked={exemption.check_100_percent_fmv}
                                                        onChange={() => onInputChange('check_100_percent_fmv', !exemption.check_100_percent_fmv)}
                                                        />
                                                    <label className="custom-control-label" htmlFor="archk-check_100_percent_fmv">
                                                        Check "100% of FMV up to statutory limit" on form
                                                    </label>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="archk-homestead_exemption"
                                                        type="checkbox"
                                                        checked={exemption.homestead_exemption}
                                                        onChange={() => onInputChange('homestead_exemption', !exemption.homestead_exemption)}
                                                        />
                                                    <label className="custom-control-label" htmlFor="archk-homestead_exemption">
                                                        Homestead Exemption exceeding $189,050
                                                    </label>
                                                </div>
                                            </FormGroup>

                                            {exemption.homestead_exemption ? (
                                            <FormGroup className='ml-4'>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="archk-homestead_acquired_1215_days"
                                                        type="checkbox"
                                                        checked={exemption.homestead_acquired_1215_days}
                                                        onChange={() => onInputChange('homestead_acquired_1215_days', !exemption.homestead_acquired_1215_days)}
                                                        />
                                                    <label className="custom-control-label" htmlFor="archk-homestead_acquired_1215_days">
                                                        Property acquired within 1215 days of filing
                                                    </label>
                                                </div>
                                            </FormGroup>
                                            ) : ''}
                                        </Col>

                                        <Col xs={6}>
                                            <FormGroup>
                                                <label className='form-control-label'>Exemption Amount: {formatCurrency(claimValue)}</label>
                                                <Input 
                                                    disabled={exemption.amount_unknown}
                                                    type="number"
                                                    value={exemption.amount}
                                                    onChange={(e) => onInputChange('amount', parseFloat(e.target.value))}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="archk-amount_unknown"
                                                        type="checkbox"
                                                        checked={exemption.amount_unknown}
                                                        onChange={() => onInputChange('amount_unknown', !exemption.amount_unknown)}
                                                        />
                                                    <label className="custom-control-label" htmlFor="archk-amount_unknown">
                                                        Amount Is Unknown
                                                    </label>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id="archk-amount_is_percent"
                                                        type="checkbox"
                                                        checked={exemption.amount_is_percent}
                                                        onChange={() => onInputChange('amount_is_percent', !exemption.amount_is_percent)}
                                                        />
                                                    <label className="custom-control-label" htmlFor="archk-amount_is_percent">
                                                        Use Percent Of Current Value
                                                    </label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        )}
                        
                         {property ? (
                            <div className='modal-body bg-secondary'>
                                <Row>
                                    <Col xs={6} className='text-left'>
                                        <p className='mb-0'>Property Total</p>
                                        <p className='text-sm mb-0'><b style={{width: 100, display: 'inline-block'}}>Current Value:</b> {formatCurrency(property.current_value)}</p>
                                        <p className='text-sm mb-0'><b style={{width: 100, display: 'inline-block'}}>- Liens:</b> {formatCurrency(lienValue)}</p>
                                        <hr className='my-2 w-50 text-dark bg-dark pr-4' />
                                        <p className='text-sm mb-0'><b style={{width: 100, display: 'inline-block'}}>Net Equity:</b> {formatCurrency(equityValue)}</p>
                                        <p className='text-sm mb-0'><b style={{width: 100, display: 'inline-block'}}>- Claimed:</b> {formatCurrency(claimValue)}</p>
                                        <hr className='my-2 w-50 text-dark bg-dark pr-4' />
                                        <p className='text-sm mb-0'><b style={{width: 100, display: 'inline-block'}}>Unexempt:</b> {formatCurrency(property.current_value - lienValue - claimValue)}</p>
                                    </Col>

                                    <Col xs={6}>
                                        <p className='mb-0'>Schedule C Display</p>
                                        {exemption.print_override ? (
                                            <p className="text-muted text-sm mb-0"><del>Print "{formatCurrency(claimValue)}" and Total {formatCurrency(claimValue)}</del></p>
                                        ) : (
                                            <p className="text-dark text-sm mb-0">Print "{formatCurrency(claimValue)}" and Total {formatCurrency(claimValue)}</p>

                                        )}

                                        <FormGroup>
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    className="custom-control-input"
                                                    id="archk-print_override"
                                                    type="checkbox"
                                                    checked={exemption.print_override}
                                                    onChange={() => onInputChange('print_override', !exemption.print_override)}
                                                    />
                                                <label className="custom-control-label" htmlFor="archk-print_override">
                                                    Override the exemption amount on Schedule C
                                                </label>
                                            </div>
                                        </FormGroup>

                                        {exemption.print_override ? (
                                            <Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label className='form-control-label'>Text To Print</label>
                                                        <Input 
                                                            type="text"
                                                            value={exemption.print_text}
                                                            onChange={(e) => onInputChange('print_text', e.target.value)}
                                                        />
                                                    </FormGroup>

                                                    <p className='text-sm text-muted mt-3 mb--2'><small>Common Defaults: (Click To Use)</small></p>

                                                    <p className='text-sm mb-0 cursor-pointer'>
                                                        <span className='cursor-pointer' onClick={() => onInputChange('print_text', 'Full Fair Market Value FMV')}>
                                                                Full Fair Market Value FMV
                                                        </span>
                                                    </p>
                                                    <p className='text-sm mb- cursor-pointer'>
                                                        <span className='cursor-pointer' onClick={() => onInputChange('print_text', '100% of FMV')}>
                                                                100% of FMV
                                                        </span>
                                                    </p>
                                                </Col>
                                                    <Col xs={6}>
                                                    <FormGroup>
                                                        <label className='form-control-label'>Amount To Print</label>
                                                        <Input 
                                                            type="text"
                                                            value={exemption.print_amount}
                                                            onChange={(e) => onInputChange('print_amount', e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        ) : ''}
                                    
                                    </Col>
                                </Row>

                            </div>
                        ) : null }




                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && exemption ? (
                    <div>
                        {exemption._id ? (
                            <button className="btn btn-outline-danger" onClick={() => setShowDelete(true)}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : ''}
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save
                        </button>
                    </div>
                ) : ''}
            </div>

        </Modal>

        <ConfirmationModal 
            showModal={showDelete}
            toggleModal={() => setShowDelete(false)}
            title="Delete Exemption"
            body={<span>Are you sure you sure to delete this exemption.</span>}
            onConfirmation={onDelete}
            zIndex={2000}
        />

        </>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalExemptionsEdit);