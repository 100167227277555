import { useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";

import { formatCurrency } from 'utils/currency';

const ModalExemptionsEdit = ({ config, matter, selected, exemptions, onSetCategory }) => {

    const [filter, setFilter] = useState('')

    const toShow = filter ? exemptions.filter(e => {
        const _filter = filter.toLowerCase()
        const description = e.description.toLowerCase()
        const law = e.law.toLowerCase()

        if(law.includes(_filter) || description.includes(_filter)) return true;
        return false;
    }) : exemptions

    return (

        <div>

            {selected ? (
                <div className='my-3 border px-4 py-3 bg-info text-white text-uppercase cursor-pointer' onClick={() => onSetCategory(selected)}>
                    <p className='text-m mb-0 font-weight-bold'><b><i className="fa-solid fa-hand-pointer mr-2" /> SELECTED:</b> {selected.description} - {selected.law}</p>
                </div>
            ) : ''}

            <FormGroup>
                <Input 
                    placeholder='Search Exemptions...'
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                />
            </FormGroup>

            <div className='table-responsive border' style={{height: 300, overflow: 'auto'}}>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{width: 250}}>Description</th>
                            <th>State</th>
                            <th>Statue</th>
                            <th className='text-right'>Limit</th>
                            <th className='text-right'></th>
                        </tr>
                    </thead>

                    <tbody>
                        {toShow.map((e, i) => (
                            <tr className='border-bottom' key={i}>
                                <td>{e.description}</td>
                                <td>{e.state ? e.state : 'FED'}</td>
                                <td>{e.law}</td>
                                <td className='text-right'>{formatCurrency(e.individual_limit)}</td>
                                <td className='text-right'><button onClick={() => onSetCategory(e)} className='btn btn-success btn-sm'>Select</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalExemptionsEdit);