import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import Edit from './Edit';

import ModalToggler from 'components/functional/modals/Toggler';

import api from 'api';
import { formatCurrency } from 'utils/currency';


const Test = ({ matter, config }) => {

    const [properties, setProperties] = useState([])

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.properties.findByMatter(matter._id)
        if(result.data) setProperties(result.data)
    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6} className='align-self-center'>
                            <CardTitle className='mb-0'>All Listed Properties / Assets</CardTitle>
                        </Col>
                        <Col md={6} className='align-self-center text-right'>
                            <ModalToggler component={Edit} config={config}>
                                <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                            </ModalToggler>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Owner</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {properties.length ? properties.map((p, i) => (
                                <tr>
                                    <td style={{width: 250}}>
                                        <span className='cursor-pointer'>
                                            <ModalToggler component={Edit} config={config} onSuccess={fetchData} property_id={p._id}>
                                                {config.properties.categories.find(pp => pp.id === p.category).name}
                                            </ModalToggler>
                                        </span>
                                    </td>

                                    <td style={{width: 250}}>{p.institution_name ? p.institution_name : p.description}</td>
                                    <td>
                                        {
                                            !p.ownership ? 'N/A' :
                                            p.ownership === 'd1' ? 'D1' :
                                            p.ownership === 'd2' ? 'D2' :
                                            p.ownership === 'd1_and_d2' ? 'D1/D2' :
                                            p.ownership === 'd_and_other' ? 'D/Other' :
                                            ''
                                        }
                                    </td>
                                    <td>{formatCurrency(p.current_value)}</td>
                                    <td className='text-right'>
                                        <ModalToggler component={Edit} config={config} onSuccess={fetchData} property_id={p._id}>
                                            <button className='btn btn-sm btn-outline-info'>Edit</button>
                                        </ModalToggler>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No items have been added yet.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <ModalToggler component={Edit} config={config} onSuccess={fetchData} >
                        <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                    </ModalToggler>
                </CardFooter>
            </Card>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(Test);