
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({obj, onInputChange, title, options}) => {

    return (

        <div>

            <ReactSelect
                title={title ? title : 'Type Of Account'}
                formGroup={true}
                onChange={(obj) => onInputChange('account_type', obj.value)}
                options={options}
                value={obj.account_type}
            />    

        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);