import { FormGroup, Input } from "reactstrap";

const ContactsUpdate = ({onInputChange, obj}) => {

    return (
        <div>
        
            <FormGroup>
                <label className="form-control-label">Ownership Percent</label>
                <Input 
                    type="text"
                    value={obj.ownership_percent}
                    onChange={e => onInputChange('ownership_percent', e.target.value ? parseFloat(e.target.value) : e.target.value)}
                />
            </FormGroup>    

        </div>
    )

}

export default ContactsUpdate;