import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import Edit from './Edit';

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

const BankruptCreditors = ({ config, matter }) => {

    const [exemptions, setExemptions] = useState(null);

    const getStatue = useCallback((category) => {
        const keys = Object.keys(config.exemptions)
        let allExemptions = []

        keys.forEach(k => {
            allExemptions = allExemptions.concat(config.exemptions[k])
        })

        const found = allExemptions.find(a => a.id === category)
        return found ? `${found.law} - ${found.description}` : '-'
    }, [config.exemptions])

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.exemptions.findByMatter(matter._id)
        if(!result.success) return toast.info(`Something went wrong fetching exemptions for this case, please reload your page.`)
        setExemptions(result.data)

    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!exemptions) return <Circle />

    return (

        <div>

            <h2 className='text-warning mb-4'>Creditors</h2>

            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={6} className='align-self-center'>
                                <CardTitle className='mb-0'>All Claimed Exemptions</CardTitle>
                            </Col>
                            <Col md={6} className='align-self-center text-right'>
                                <ModalToggler component={Edit} config={config} onSuccess={fetchData}>
                                    <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                                </ModalToggler>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Exemption Amount</th>
                                    <th>Statue</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            {console.log(exemptions)}

                            <tbody>
                                {exemptions.length ? exemptions.map((p, i) => (
                                    <tr>
                                        <td>
                                            <span className='cursor-pointer'>
                                                <ModalToggler component={Edit} config={config} onSuccess={fetchData} exemption_id={p._id}>
                                                {config.properties.categories.find(pp => p.bk_property && p.bk_property.category === pp.id ).name}

                                                </ModalToggler>
                                            </span>
                                        </td>
                                        <td className='text-capitalize'>
                                           ***Calculation Coming
                                        </td>

                                        <td>{getStatue(p.category)}</td>


                                        <td className='text-right'>
                                            <ModalToggler component={Edit} config={config} onSuccess={fetchData} exemption_id={p._id}>
                                                <button className='btn btn-sm btn-outline-info'><i className="fas fa-edit mr-2" /> Edit</button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No creditors have been added yet.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <CardFooter>
                        <ModalToggler component={Edit} config={config} onSuccess={fetchData}>
                            <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                        </ModalToggler>
                    </CardFooter>
                </Card>
            </div>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(BankruptCreditors);