import { connect } from 'react-redux';
import { FormGroup } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({obj, onInputChange}) => {

    const investment = `property_type_is_investment`;
    const timeshare = `property_type_is_timeshare`;

    return (

        <div>

            <ReactSelect
                title="What Is The Property Type"
                formGroup={true}
                onChange={(obj) => onInputChange('property_type', obj.value)}
                options={[
                    { value: 'single_family', label: 'Single Family Home' },
                    { value: 'duplex_or_multi', label: 'Duplex Or Multi Unit Building' },
                    { value: 'condominium_or_cooperative', label: 'Condominium Or Cooperative' },
                    { value: 'manufactured_or_mobile', label: 'Manufactured Or Mobile Home' },
                    { value: 'land', label: 'Land' },
                    { value: 'other', label: 'Other' },
                ]}
                value={obj.property_type}
            />    

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-property-type-is-investment`}
                        type="checkbox"
                        checked={obj[investment] || false}
                        onChange={() => onInputChange(investment, !obj[investment])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-property-type-is-investment`}>
                        Is this an investment property?
                    </label>
                </div>
            </FormGroup>

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-property-type-is-timeshare`}
                        type="checkbox"
                        checked={obj[timeshare] || false}
                        onChange={() => onInputChange(timeshare, !obj[timeshare])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-property-type-is-timeshare`}>
                        Is this property a timeshare?
                    </label>
                </div>
            </FormGroup>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);