// import { connect } from 'react-redux';
// import { Card, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

// import ReactSelect from 'components/functional/inputs/ReactSelect';




// const ModuleBKOverview = ({ data, onInputChange }) => {
//     if(data.chapter === 7) return <></>
//     return (
//         <div>
//               <Card>
//                 <CardHeader>
//                     <CardTitle className="mb-0">Confirmation Hearing</CardTitle>
//                 </CardHeader>

//                 <CardFooter>

//                     <ReactSelect
//                         title="Confirmation Held / Concluded?"
//                         formGroup={true}
//                         onChange={(obj) => onInputChange('hearing_confirmation_accepted', obj.value)}
//                         options={[
//                             { value: false, label: 'No' },
//                             { value: true, label: 'Yes' },
//                         ]}
//                         value={data.hearing_confirmation_accepted}
//                     /> 

//                 </CardFooter>

//                 <CardFooter> 
//                     <FormGroup>
//                         <label className='form-control-label'>Confirmation Not Held / Unmet Condition Notes</label>
//                         <Input 
//                             type="textarea"
//                             style={{minHeight: 60}}
//                             value={data.hearing_confirmation_notes}
//                             onChange={e => onInputChange('hearing_confirmation_notes', e.target.value)}
//                         />
//                     </FormGroup>
//                 </CardFooter>

//             </Card>
//         </div>


//     )
// }


// const mapStateToProps = state => {
// 	return {
//         matter: state.matters.selected_matter,
// 	};
// };

// export default connect(mapStateToProps, '')(ModuleBKOverview);




import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup } from 'reactstrap';


const ModuleBKOverview = ({ data }) => {
    const hearings = data.hearing_results ? data.hearing_results.filter(r => r.type === 'confirmation') : []
    const recentHearing = hearings.length ? hearings[hearings.length - 1 ] : ''

    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Confirmation Hearing</CardTitle>
                </CardHeader>

                <CardFooter>

                    <label className='form-control-label'>
                        {recentHearing && recentHearing.outcome === 'confirmed' ? (
                            <i className="fas fa-check text-success mr-2" />
                        ) : (
                            <i className="fas fa-times text-danger mr-2" />
                        )}
                        Case Confirmed
                    </label>


                </CardFooter>

                {recentHearing && recentHearing.notes ? (
                    <CardFooter> 
                        <FormGroup>
                            <label className='form-control-label'>Attorney Notes</label>
    
                            <div className='p-3 border bg-secondary'>
                                <p className="text-sm mb-0"><span>{recentHearing.notes}</span></p>
                            </div>
                        </FormGroup>
                    </CardFooter>
                ) : ''}
              

                <CardFooter>
                    <p className='text-sm mb-0'><i className="fas fa-info-circle mr-2 text-info" /> To update this section, enter the confirmation hearing results under the confirmation Post Filed section.</p>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


