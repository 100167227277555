import { connect } from 'react-redux';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

import { Alert, Card, Container, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import api from 'api';
import Table from 'components/functional/tables/Standard';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

import A from 'components/markup/links/A';

import ObjectFinder from 'components/system/Objects/Finder';

import { toast } from 'react-toastify';


import ConfirmationModal from 'components/functional/modals/Confirmation'
// import CopyToClipboard from 'react-copy-to-clipboard'


//  <CopyToClipboard 
//  text={keys.apiKey}
//  onCopy={onSetCopiedKey}
//  >
//  <i className="fas fa-copy cursor-pointer text-success mr-3" />
// </CopyToClipboard>
       

const UserCommentsAll = ({ selected_division }) => {

    const [err, setErr] = useState(false);
    const [toDelete, setToDelete] = useState(false);
    const [newCases, setNewCases] = useState(false);
    const [chapter, setChapter] = useState('all');

    const filteredCases = newCases ? newCases.filter(c => {
        if(chapter === 'all') return true;
        return c.chapter === chapter
    }) : newCases

    const getCourtCode = useCallback((obj) => {
        if(obj.court_code === 'miebke') return 'Eastern District Of Michigan' ;
        if(obj.court_code === 'miwbke') return 'Western District Of Michigan' ;
        if(obj.court_code === 'ohnbke') return 'Northern District Of Ohio' ;
        if(obj.court_code === 'ohsbke') return 'Southern District Of Ohio' ;
        return ''
    }, [])


    const fetchData = useCallback(async () => {
        if(!selected_division._id) return;

        const result = await api.modules.bk.new_case_entries.find(selected_division._id);
        if(!result.success) return setErr(true)

        setNewCases(result.data)
    }, [selected_division._id])

    const onDelete = useCallback(async () => {
        const result = await api.modules.bk.new_case_entries.delete(toDelete._id);
        if(!result.success) return toast.error(`Could not delete entry at this time, please try again.`)

        fetchData()

    }, [fetchData, toDelete._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    console.log(newCases)

    return (
        <>
    
            <Helmet>
                <title>{`Newly Filed Cases`}</title>
                <meta name="description" content="Most Recent 500" />
            </Helmet>

            <HeaderNavigation 
                title={"Newly Filed Cases"}
                description="Most Recent 500"
                leftColSize={5}
                actionComponent={(
                    <div >
                        <UncontrolledDropdown>
                            <DropdownToggle color={'info'} style={{width: 150}}>
                                {
                                chapter === 'all' ? 'All Chapters' :
                                chapter === 13 ? 'Chapter 13' :
                                chapter === 7 ? 'Chapter 7' :
                                chapter === 0 ? 'Unknown Chapter' :
                                ''
                            }
                            </DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={e => setChapter('all')}>All</DropdownItem>
                                <DropdownItem onClick={e => setChapter(13)}>Chapter 13</DropdownItem>
                                <DropdownItem onClick={e => setChapter(7)}>Chapter 7</DropdownItem>
                                <DropdownItem onClick={e => setChapter(0)}>Unknown</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
    
                    </div>
                )}
            />
    
            <Container fluid>
                {!selected_division._id ? (
                    <Container fluid >
                        <Alert color="danger">A division must be selected to view this page.</Alert>
                    </Container>
                ) : err ? (
                    <Container fluid >
                        <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
                    </Container>
                ) : !newCases ? (
                    <Circle />
                ) : (
                    <Card className="card-color card-primary">    
                        {!filteredCases.length ? (
                            <CardBody>
                                <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> No new cases currently found.</p>
                            </CardBody>
                        ) : (
                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Case Number</th>
                                            <th>Court Code</th>
                                            <th>Docket Text</th>
                                            <th>Chapter</th>
                                            <th className='text-right'>Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {filteredCases.map(c => (
                                            <tr key={c}>
                                                <td>{c.case_number}</td>
                                                <td>{getCourtCode(c)}</td>
                                                <td>{c.docket_text}</td>
                                                <td>{c.chapter ? c.chapter : 'unknown'}</td>
                                                <td className='text-right' style={{width: 100}}>{moment.unix(c.created_at).format('MM/DD/YYYY')}</td>
                                                <td>
                                                    <button 
                                                        className='btn btn-sm btn-danger'
                                                        onClick={() => setToDelete(c)}
                                                    >
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Card>
                )}
               

            </Container>

            <ConfirmationModal 
                showModal={toDelete ? true : false}
                toggleModal={() => setToDelete(false)}
                title="Delete Entry"
                body={<span>Are you sure you wish to remove the entry <b className='text-dark font-weight-bold'>"{toDelete.case_number}" - "{getCourtCode(toDelete)}"</b>? New case entries will automatically be deleted when entering their case number and court code on a matter in the "BK Filing" section</span>}
                onConfirmation={onDelete}
            />
    
        </>
    )

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(UserCommentsAll);
