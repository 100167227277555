import { FormGroup, Input } from "reactstrap";

const ContactsUpdate = ({title, onInputChange, obj}) => {

    return (
        <FormGroup>
            <label className="form-control-label">{title ? title : 'Description'}</label>
            <Input 
                style={{minHeight: 50}}
                type="textarea"
                value={obj.description}
                onChange={e => onInputChange('description', e.target.value)}
            />
        </FormGroup>    
    )

}

export default ContactsUpdate;