import { useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { useCallback } from 'react';

import api from 'api';

import { toast } from 'react-toastify';

import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment';

const ModuleBKOverview = ({ bkCase, type, setBkCase, stage }) => {

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const results = bkCase.hearing_results.filter(f => f.type === type)

    const onInputChange = useCallback((field, val) => {
        const newState = JSON.parse(JSON.stringify(state))
        newState[field] = val;
        setState(newState)
    }, [state])

    const onAddResult = useCallback(async () => {
        if(!state.notes) return toast.info(`Notes must be entered before entering the hearing results`)
        if(!state.outcome) return toast.info(`The outcome must be entered before entering the hearing results`)
        setLoading(true)
        const result = await api.modules.bk.cases.addHearingResult(bkCase._id, { ...state, type })

        setLoading(false)
        if(!result.success) return toast.error(`Could not enter results, please try again.`)

        setBkCase(result.data)
        setState({ note: '', outcome: '' })

        toast.success(`Results entered successfully.`)

    }, [bkCase._id, setBkCase, state, type])
  
    return (

        <div className='bg-secondary border p-4 z-depth-1 rounded'>
            <Card className='z-depth-'>
                <CardHeader>
             

                    <CardTitle className="mb-0 text-capitalize">
                        {type} Results 
                        <span className='float-right'>
                            Hearing Date: {' '}
                            {type === '341' ? bkCase.hearing_341_date ? moment(bkCase.hearing_341_date).format('MM/DD/YYYY') : '-' : ''}
                            {type === 'confirmation' ? bkCase.hearing_confirmation_date ? moment(bkCase.hearing_confirmation_date).format('MM/DD/YYYY') : '-' : ''}

                        </span>
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <label className='form-control-labe'>Enter Attorney Hearing Notes</label>
                                <Input 
                                    type="textarea"
                                    placeholder='Held and...'
                                    value={state.notes}
                                    style={{minHeight: 60}}
                                    onChange={e => onInputChange('notes', e.target.value)}
                                />
                            </FormGroup>
                    
                        </Col>
                        <Col md={12}>
                            <ReactSelect
                                title={<span>Result Of Hearing</span>}
                                formGroup={true}
                                onChange={(obj) => onInputChange('outcome', obj.value)}
                                options={type === '341' ? [
                                    { value: 'concluded', label: 'Held / Concluded' },
                                    { value: 'adjourned', label: 'Not Held' },
                                ] : [
                                    { value: 'confirmed', label: 'Case Confirmed' },
                                    { value: 'adjourned_minute_entry', label: 'Adjourned - Minute Entry' },
                                    { value: 'adjourned_order', label: 'Adjourned - Order' },
                                ]}

                                value={state.outcome}
                            /> 
                        </Col>
                    </Row>
                    
                </CardBody>

                <CardFooter className='text-right'>
                    <button disabled={loading} onClick={onAddResult} className='btn btn-success btn-sm'>Enter Hearing Results</button>
                </CardFooter>
            </Card>

            <Card className='z-depth-'>
                <CardHeader>
                    <CardTitle className="mb-0">Previous Results</CardTitle>
                </CardHeader>

                <table className='table'>
                    <thead>
                        <tr>
                            <th>Date / Entered By</th>
                            <th>Notes</th>
                            <th>Result</th>
                        </tr>
                    </thead>

                    <tbody>
                        {results.length ? results.map(r => (
                            <tr key={r._id}>
                                <td>{moment.unix(r.timestamp).format('MM/DD/YYYY')} <div><ObjectFinder collection="users" _id={r.user} /></div></td>
                                <td>{r.notes}.</td>
                                <td className='text-capitalize'>{r.outcome.replace(/_/g, ' ')}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No results entered.</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                    </tbody>
                </table>


            </Card>

        </div>

    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


