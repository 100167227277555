import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import ConfirmationModal from 'components/functional/modals/Confirmation';
import States from 'components/markup/inputs/States'
import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

import { toast } from 'react-toastify';

const baseState = {
    other_party                   : '',
    description                   : '',
    
    hide_schedule_g               : false,
    exclude_from_mailing_matrix   : false,
    list_lease_on_108             : false,
    
    intention                     : 'none',

    address_line_1                : '',
    address_line_2                : '',
    city                          : '',
    state                         : '',
    postal_code                   : '',
}

const ModalContractsEdit = ({showModal, toggleModal, config, onSuccess, contract_id, matter}) => {

    const [err, setErr] = useState(false)
    const [contract, setContract] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const onInputChange = useCallback((field, val) => {
        const _contract = JSON.parse(JSON.stringify(contract));
        _contract[field] = val;
        setContract(_contract)
    }, [contract])
  

    const onSave = useCallback(async () => {

        const params = {
            ...contract,
            matter: matter._id,
        }

        let result;

        if(contract_id) {
            result = await api.modules.bk.contracts.update(contract._id, params)
        } else {
            result = await api.modules.bk.contracts.create(params)
        }

        if(!result.success) return toast.error(`Something went wrong updating this contract, please refresh your page`)
        
        toast.success(`Contract updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [contract, contract_id, matter._id, onSuccess, toggleModal])
  
    const onDelete = useCallback(async () => {
        if(!contract.name) return toast.info(`A name must be set before updating a contract`);

        const result = await api.modules.bk.contracts.delete(contract._id)

        if(!result.success) return toast.error(`Something went wrong deleting this contract, please refresh your page`)
        
        toast.success(`Contract deleted successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [contract, onSuccess, toggleModal])

    const fetchData = useCallback(async () => {
        if(showModal) {
            if(contract_id) {
                const result = await api.modules.bk.contracts.findById(contract_id);
                if(!result.success) return setErr(true);
                setContract(result.data)                
            } else {
                setContract(baseState)
            }
           
        } else {
            setErr(false)
        }
    }, [contract_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Contract / Lease</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this contract, please refresh your page.</div>
                </div>
            ) : !contract ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                    <div className="modal-body border-bottom">

                        <FormGroup>
                            <label className='form-control-label'>Other Party</label>
                            <Input 
                                type="text"
                                value={contract.other_party}
                                onChange={(e) => onInputChange('other_party', e.target.value)}
                            />
                        </FormGroup>

                    </div>

                    <div className="modal-body border-bottom bg-secondary">

                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={contract.address_line_1}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={contract.address_line_2}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={contract.city}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <States 
                                    title="State"
                                    value={contract.state}
                                    onChange={obj => onInputChange('state', obj.value)}
                                /> 
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={contract.postal_code}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>

                    </div>

                    <div className="modal-body  border-bottom">
                        <Row>
                            <Col md={6}>

                                <FormGroup>
                                    <label className='form-control-label'>Description of Contract or Lease</label>
                                    <Input 
                                        type="textarea"
                                        style={{minHeight: 120}}
                                        value={contract.description}
                                        onChange={(e) => onInputChange('description', e.target.value)}
                                    />
                                </FormGroup>

                            </Col>

                            <Col xs={6}>

                                <ReactSelect
                                    title="Intention For Contract / Lease"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('intention', obj.value)}
                                    options={[
                                        { value: 'none', label: 'None' },
                                        { value: 'assume', label: 'Assume' },
                                        { value: 'reject', label: 'Reject' },
                                    ]}
                                    value={contract.intention}
                                />    
                                
                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-hide_schedule_g"
                                            type="checkbox"
                                            checked={contract.hide_schedule_g}
                                            onChange={() => onInputChange('hide_schedule_g', !contract.hide_schedule_g)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-hide_schedule_g">
                                            Do not print on Schedule G
                                        </label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-exclude_from_mailing_matrix"
                                            type="checkbox"
                                            checked={contract.exclude_from_mailing_matrix}
                                            onChange={() => onInputChange('exclude_from_mailing_matrix', !contract.exclude_from_mailing_matrix)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-exclude_from_mailing_matrix">
                                            Exclude from Mailing Matrix
                                        </label>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-list_lease_on_108"
                                            type="checkbox"
                                            checked={contract.list_lease_on_108}
                                            onChange={() => onInputChange('list_lease_on_108', !contract.list_lease_on_108)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-list_lease_on_108">
                                            List Lease on Form 108 If converted to Chapter 7
                                        </label>
                                    </div>
                                </FormGroup>

                                

                            </Col>
                        </Row>
                    </div>

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && contract ? (
                    <div>
                        {contract._id ? (
                            <button className="btn btn-outline-danger" onClick={() => setShowDelete(true)}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : ''}
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save
                        </button>
                    </div>
                ) : ''}
            </div>

        </Modal>

        <ConfirmationModal 
            showModal={showDelete}
            toggleModal={() => setShowDelete(false)}
            title="Delete Contract"
            body={<span>Are you sure you sure to delete this contract.</span>}
            onConfirmation={onDelete}
            zIndex={2000}
        />

        </>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalContractsEdit);