
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({obj, onInputChange}) => {

    return (

        <div>

            <ReactSelect
                title="Nature Of Interest"
                formGroup={true}
                onChange={(obj) => onInputChange('nature_of_interest', obj.value)}
                options={[
                    { value: 'equitable_interest', label: 'Equitable Interest' },
                    { value: 'fee_simple', label: 'Fee Simple' },
                    { value: 'future_interest', label: 'Future Interest' },
                    { value: 'joint_tenant', label: 'Joint Tenant' },
                    { value: 'life_estate', label: 'Life Estate' },
                ]}
                value={obj.nature_of_interest}
            />    

        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);