import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

const BankruptCreditorsSearch = ({ matter, title, description, value, onChange }) => {

    const [properties, setProperties] = useState([])

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.properties.findByMatter(matter._id)
        setProperties(result.data ? result.data : [])
    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <ReactSelect
            title={title ? title : "Select Creditor"}
            description={description}
            formGroup={true}
            onChange={(obj) => onChange(obj)}
            options={properties.map(c => {
                return { value: c._id, label: c.institution_name ? c.institution_name : c.description }
            })}
            value={value}
        />    

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(BankruptCreditorsSearch);