import { FormGroup, Input } from "reactstrap";

const ContactsUpdate = ({onInputChange, obj}) => {

    return (
        <div>
        
            <FormGroup>
                <label className="form-control-label">Beneficiary Name</label>
                <Input 
                    type="text"
                    value={obj.beneficiary}
                    onChange={e => onInputChange('beneficiary',  e.target.value)}
                />
            </FormGroup>    

        </div>
    )

}

export default ContactsUpdate;