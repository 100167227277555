import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import moment from 'moment'

const ModuleBKOverview = ({ bkCase }) => {
  
    return (
        <Card className='rounded'>
            <CardHeader className='rounded bg-secondary'>
                <CardTitle className="mb-0">Case Information</CardTitle>
            </CardHeader>

            <CardBody>
                <Row>
                    <Col md={6}>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Case Name: </b> {bkCase.case_title}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Case Number: </b> {bkCase.case_number}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Attorney: </b> {bkCase.attorney}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Judge: </b> {bkCase.judge}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Debtor 1: </b> {bkCase.debtor_1_name}</p>
                    </Col>
                    <Col md={6}>
                        <p className='mb-0 text-sm text-capitalize'><b className='d-inline-block text-muted ' style={{width: 120}}>Chapter: </b> {bkCase.chapter} - {bkCase.case_type}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Court: </b> {bkCase.court_code}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Trustee: </b> {bkCase.trustee}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Date Filed: </b> {bkCase.date_filed ? moment(bkCase.date_filed).format('MM/DD/YYYY') : ''}</p>
                        <p className='mb-0 text-sm'><b className='d-inline-block text-muted' style={{width: 120}}>Debtor 2: </b> {bkCase.debtor_2_name ? bkCase.debtor_2_name : ''}</p>
                    </Col>

                </Row>
            </CardBody>
        </Card>

    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


