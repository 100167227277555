import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import Edit from './Edit';

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';


const BankruptCreditors = ({matter}) => {

    const [creditors, setCreditors] = useState(null);

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.creditors.findByMatter(matter._id)
        if(!result.success) return toast.info(`Something went wrong fetching creditors for this case, please reload your page.`)
        setCreditors(result.data)

    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!creditors) return <Circle />

    return (

        <div>

            <h2 className='text-warning mb-4'>Creditors</h2>

            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={6} className='align-self-center'>
                                <CardTitle className='mb-0'>All Listed Creditors</CardTitle>
                            </Col>
                            <Col md={6} className='align-self-center text-right'>
                                <ModalToggler component={Edit} onSuccess={fetchData}>
                                    <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                                </ModalToggler>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>

                                    <th>Balance</th>
                                    <th>Monthly Payment</th>
                                    
                                    <th>Contact Info</th>
                                    <th>Address</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            <tbody>
                                {creditors.length ? creditors.map((p, i) => (
                                    <tr key={p._id}>
                                        <td>
                                            <span className='cursor-pointer'>
                                                <ModalToggler component={Edit} onSuccess={fetchData} creditor_id={p._id}>
                                                    {p.name}
                                                </ModalToggler>
                                            </span>
                                        </td>
                                        <td className='text-capitalize'>
                                            {
                                                p.schedule === 'D' ? 'Secured' : 
                                                p.schedule === 'E' ? 'Priority' : 
                                                p.schedule === 'F' ? 'Unsecured' : 
                                                ''
                                            }
                                        </td>

                                        <td>{formatCurrency(p.balance)}</td>
                                        <td>{formatCurrency(p.monthly_payment)}</td>
                                        <td>
                                            <div>
                                                <div>{p.email}</div>
                                                <div>{p.phone ? formatText(p.phone).phone() : ''}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>{p.street ? p.street : ''}</div>
                                                <div>{p.city ? p.city : ''} {p.state ? p.state : ''} {p.postal_code ? p.postal_code : ''}</div>
                                            </div>
                                        </td>

                                        <td className='text-right'>
                                            <ModalToggler component={Edit} onSuccess={fetchData} creditor_id={p._id}>
                                                <button className='btn btn-sm btn-outline-info'><i className="fas fa-edit mr-2" /> Edit</button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No creditors have been added yet.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <CardFooter>
                        <ModalToggler component={Edit} onSuccess={fetchData}>
                            <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                        </ModalToggler>
                    </CardFooter>
                </Card>
            </div>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(BankruptCreditors);