import { connect } from 'react-redux';

import { Card, CardHeader, CardTitle, CardFooter,  CardBody } from "reactstrap";


import { useState } from 'react'
import formatText from 'utils/formatText';
import moment from 'moment';

import reactHTMLParser from 'html-react-parser';
import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A';

import CallContactBadge from 'components/system/CallContact/Badge'


import SearchCollections from "components/system/Search/Collections";
import { useCallback } from 'react';

import api from 'api'

import { toast } from 'react-toastify'

const ContactsOverview = ({contact, onSaved, viewing_user}) => {

    const [divisions, setDivisions] = useState(contact.divisions)

    const onChange = useCallback((division, call_phone_number) => {
        const _divisions = JSON.parse(JSON.stringify(divisions));

        const foundIndex = _divisions.findIndex(d => d.division === division);

        if(foundIndex === -1) {
            _divisions.push({ division, call_phone_number })
        } else {
            _divisions[foundIndex].call_phone_number = call_phone_number
        }

        setDivisions(_divisions)

    }, [divisions])

    const onSave = useCallback(async () => {
        const saved = await api.contacts.divisions(contact._id, { divisions })
        if(!saved.success) return toast.error(`Record was not saved successfully, please try again.`)
        toast.success(`Contact record saved successfully.`)
        onSaved()
    }, [contact._id, divisions, onSaved])
  
    return (

        <>

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">Division Settings</CardTitle>
            </CardHeader>

            {viewing_user.divisions.length ? viewing_user.divisions.map(d => {
                const found = divisions.find(dd => dd.division === d);

                return (
                    <CardFooter key={d}>
                        <h3><ObjectFinder collection="divisions" _id={d} /></h3>
                        <SearchCollections
                            collection={'call_phone_numbers'}
                            showEnhanced="call_phone_numbers"
                            title={<span>Outgoing Phone Number</span>}
                            placeholder="Search Numbers..."
                            value={found ? found.call_phone_number : ''}
                            onChange={(obj) => onChange(d, obj.value)}
                            filter={{
                                division: d,
                                sync_status: 'full',
                                hide_from_assignment: { $ne: true }
                            }}
                        /> 
                    </CardFooter>
                )
            }) : (
                <CardBody>
                    <p className='text-sm mb-0'>No division settings are able to be set at this time.</p>
                </CardBody>
            )}

            <CardFooter className='text-right'>
                <button onClick={onSave} className='btn btn-success'><i className="fas fa-save mr-2" /> Save Settings</button>
            </CardFooter>

        </Card>

        </>

    );

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ContactsOverview);