
import { connect } from 'react-redux';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({title, obj, onInputChange}) => {

    return (

        <div>

            <ReactSelect
                title={title ? title : 'Who Owns This Item'}
                formGroup={true}
                onChange={(obj) => onInputChange('ownership', obj.value)}
                options={[
                    { value: 'd1', label: 'Debtor 1' },
                    { value: 'd2', label: 'Debtor 2' },
                    { value: 'd1_and_d2', label: 'Debtor 1 & 2' },
                    { value: 'd_and_other', label: 'At least one debtor and another' },
                ]}
                value={obj.ownership}
            />    

        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);