import { useCallback, useEffect, useState } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';
// import _case_parties from '_functions/case_parties';
import SearchCollections from "components/system/Search/Collections";


import { Modal } from "reactstrap";
import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalSelectEmail = ({showModal, toggleModal, contact, onSelect}) => {

    const [email, setEmail] = useState(contact ? contact.email : '');

    const onConfirm = useCallback(() => {
        const obj = { ...contact, selected_email: email }
        onSelect({obj})
        toggleModal()
    }, [contact, email, onSelect, toggleModal])

    useEffect(() => {
        if(contact) setEmail(contact.email)
    }, [contact])

    if(!contact) return <></>

    let options = []
    if(contact.email) options.push({label: contact.email, value: contact.email});
    if(contact.email_2) options.push({label: contact.email_2, value: contact.email_2});
    if(contact.email_3) options.push({label: contact.email_3, value: contact.email_3});

  

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="sm"
        >

           <div className="modal-header">
               <h5 className="modal-title">Select Email</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">
                <ReactSelect
                    value={email}
                    options={options}
                    onChange={(e) => setEmail(e.value)}
                />
           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onConfirm}>
                    Select Email
                </button>
           </div>

        </Modal>

    )
}

const SendEmailTo = ({editingRecipients, setEditingRecipients, to, onToChange, foundMatter}) => {

    const [contactToSelect, setContactToSelect] = useState(null)
    const [search, setSearch] = useState(false)

    const onRemoveTo = useCallback((contact_id) => {
        let _to = JSON.parse(JSON.stringify(to))
        _to = _to.filter(contact => contact._id !== contact_id)
        onToChange(_to)
    }, [to, onToChange])

    const onAddTo = useCallback((obj, confirmed) => {
        const contact = obj.obj

        if(!confirmed) {
            let emails = 0;
            if(contact.email) emails++
            if(contact.email_2) emails++
            if(contact.email_3) emails++

            if(emails > 1) return setContactToSelect(contact)
        }

        let _to = JSON.parse(JSON.stringify(to))
        const foundIndex = _to.findIndex(to_contact => to_contact._id === contact._id)

        if(foundIndex === -1) {
            _to.push(contact)    
        } else {
            _to[foundIndex] = contact
        }

        onToChange(_to)
    }, [to, onToChange])

    useEffect(() => {
        if(!editingRecipients) setSearch(false)
    }, [editingRecipients])

    if(editingRecipients) return (
        <div className="to-container editing bg-secondary">

            <ModalSelectEmail 
                contact={contactToSelect}
                showModal={contactToSelect ? true : false}
                toggleModal={() => setContactToSelect(null)}
                onSelect={(contact) => onAddTo(contact, true)}
            />

            <div className='section'>
                <span style={{display: 'inline-block', width: 90}}>Recipients: {' '}</span>
                {to && to.length ? to.map(contact => (
                    <span className='span' key={contact._id}>{contact.selected_email ? contact.selected_email : contact.email}
                        <b><i onClick={() => onRemoveTo(contact._id)} className="fas fa-times" /></b>
                    </span>
                )): (
                    <span className='span bg-info text-white' >No Recipients Selected
                        <b> </b>
                    </span>
                )}
            </div>

            {foundMatter ? (
                <div className='section'>
                    <span style={{display: 'inline-block', width: 90}}>Add Parties: {' '}</span>
    
                    {foundMatter.parties.map(p => (
                        !p.contact ? <></> : 
                        to.some(to_contact => to_contact &&  p.contact && to_contact._id === p.contact._id) ? <span key={p.contact._id}></span> : 
                        p.contact.email ? (
                            <Badge 
                                key={p.contact._id}
                                onClick={() => onAddTo({obj: p.contact})} 
                                color="success" 
                                className='cursor-pointer mb-2 mr-2'
                            >
                                {p.workflow_contact.name} - {p.contact.display_name} <i className="fas fa-plus ml-3" />
                            </Badge>
                        ) : (
                            <span key={p.contact._id}>
                                <Badge 
                                    id={`archk-send-email-no-email-${p.contact._id}`}
                                    color="warning" 
                                    className='mr-2 mb-2'
                                >
                                    {p.workflow_contact.name} - {p.contact.display_name}
                                </Badge>
        
                                <UncontrolledTooltip delay={0} placement="bottom" target={`archk-send-email-no-email-${p.contact._id}`}>
                                    An email was not found on this record.
                                </UncontrolledTooltip>
    
                            </span>
                        )
                    
                    ))}
    
                </div>
            ) : ''}
           

            {foundMatter ? (
                <div className='section'>
                    <span style={{display: 'inline-block', width: 90}}>Add Roles: {' '}</span>
    
                    {foundMatter.roles.map(p => (
                        !p.contact ? <></> : 
                        to.some(to_contact => to_contact._id === p.user._id) ? <span key={p.user._id}></span> : 
                        p.user.email ? (
                            <Badge 
                                key={p.user._id}
                                onClick={() => onAddTo({obj: { ...p.user, type: 'user' }})} 
                                color="success" 
                                className='cursor-pointer mr-2'
                            >
                                {p.workflow_role.name} - {p.user.display_name} <i className="fas fa-plus ml-3" />
                            </Badge>
                        ) : (
                            <span key={p.user._id}>
                                <Badge 
                                    id={`archk-send-email-no-email-role-${p.user._id}`}
                                    color="warning" 
                                    className='mr-2'
                                >
                                    {p.workflow_role.name} - {p.user.display_name}
                                </Badge>
        
                                <UncontrolledTooltip delay={0} placement="bottom" target={`archk-send-email-no-email-role-${p.user._id}`}>
                                    An email was not found on this record.
                                </UncontrolledTooltip>
        
                            </span>
                        )
                    
                    ))}
                </div>
            ) : ''}
           

            {search ? (
                <div className='pt-4 section'>
                    <SearchCollections
                        collection={'contacts'}
                        includeFields={['email', 'email_2', 'email_3']}
                        placeholder="Search Contacts..."
                        hideSelected={true}
                        value={{}}
                        onChange={(obj) => onAddTo(obj)}
                    /> 
                </div>
            ): (
                <div className='section border-bottom-none'>
                    <p onClick={()=> setSearch(true)} className='text-sm mb-0 text-cente'>
                        <span className='cursor-pointer text-dark font-weight-bold'>
                            <i className="fas fa-search mr-2 text-info" /><b>Or Click Here To Search Contacts...</b>
                        </span>
                    </p>
                </div>
            )}

        </div>
    )

    return (
        <div className="to-container " onClick={() => setEditingRecipients(true)}>
            Recipients: {' '}

            {to && to.length ? to.map(contact => (
                <span className='span' key={contact._id}>
                    {contact.display_name ? contact.display_name : contact.email}
                    {contact.email_2 || contact.email_3 ? <i onClick={() => setContactToSelect(contact)} className="fas fa-caret-down pl-2 text-dark cursor-pointer" /> : ''}
                </span>
            )): (
                <Badge color="warning" className='cursor-pointer ml-3'>Click To Add</Badge>
            )}

        </div>
    )

}


export default SendEmailTo
