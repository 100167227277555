import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';


import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';


import Edit from 'views/dashboard/pages/matters/View/Main/OutstandingItems/Edit';
import ItemList from 'views/dashboard/pages/matters/View/Main/OutstandingItems/ItemList';

import InfoBanner from '../_components/InfoBanner';


const ModuleBKOverview = ({ bkCase, items, fetchItems, stage }) => {

    const itemsPending = items ? items.filter(i => !i.finished_at) : null
    const itemsFinished = items ? items.filter(i => i.finished_at) : null

    if(!itemsPending && !itemsFinished) return <Circle />
  
    return (
        <div>

            <div className={`mb-3 rounde text-center border py-3 px-4 text-capitalize bg-gradient-${itemsPending && itemsPending.length ? 'danger' : 'success'}`}>
                <h2 className='text-white'>Follow Up Tasks</h2>
            </div>

            <InfoBanner bkCase={bkCase} />

            <hr />

            <ItemList 
                onItemChange={fetchItems} 
                modalIndex={2500} 
                items={itemsPending} 
                finished={false} 
                hideAddNew={true} 
                cardHeader={(
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h2 className='text-warning '>Follow Up Tasks</h2>
                        </Col>
                        <Col md={6} className="align-self-center text-right">

                            <ModalToggler 
                                title="Edit A Follow Up"
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                defaultIsDeadline={false}
                                defaultCategory={'bk_follow_up'}
                                defaultLockTitles={true}
                                itemTypes={[
                                    {
                                        label: "Client",
                                        options: [
                                            { value: 'Follow up with CLIENT(s)', label: 'Follow up with CLIENT(s)' },
                                        ]
                                    },
                                    {
                                        label: "Trustee",
                                        options: [
                                            { value: 'Follow up with TRUSTEE', label: 'Follow up with TRUSTEE' },
                                        ]
                                    },
                                    {
                                        label: "Creditor",
                                        options: [
                                            { value: 'Follow up with CREDITOR', label: 'Follow up with CREDITOR' },
                                        ]
                                    },
                                    {
                                        label: "Drafts",
                                        options: [
                                            { value: 'Draft Documents', label: 'Draft Documents' },
                                        ]
                                    },
                                    {
                                        label: "Other",
                                        options: [
                                            { value: 'Follow up OTHER', label: 'Follow up OTHER' },
                                        ]
                                    },
                                ]}
                            >
                                <button className='btn btn-outline-success  btn-sm'>
                                    Add Task <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler>

                        </Col>
                    </Row>
                )}
            />



        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


