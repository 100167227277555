import { connect } from 'react-redux';
import { Col, Row, Card, CardHeader,CardBody,  CardTitle } from 'reactstrap';


import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';


import Edit from 'views/dashboard/pages/matters/View/Main/OutstandingItems/Edit';
import ItemList from 'views/dashboard/pages/matters/View/Main/OutstandingItems/ItemList';

import InfoBanner from '../_components/InfoBanner';


const ModuleBKOverview = ({ bkCase, onMoveStage, items, fetchItems, stage }) => {

    const itemsPending = items ? items.filter(i => !i.finished_at) : null
    const itemsFinished = items ? items.filter(i => i.finished_at) : null

    if(!itemsPending && !itemsFinished) return <Circle />
  
    return (
        <div>

            <div className={`mb-3 rounde text-center border py-3 px-4 text-capitalize bg-gradient-${itemsPending && itemsPending.length ? 'danger' : 'success'}`}>
                <h2 className='text-white'>Hearing Dates</h2>
            </div>

            <InfoBanner bkCase={bkCase} />

            <hr />

            <ItemList 
                onItemChange={fetchItems} 
                modalIndex={2500} 
                items={itemsPending} 
                finished={false} 
                hideAddNew={true} 
                cardHeader={(
                    <Row>
                        <Col md={6} className="align-self-center">
                            <h2 className='text-warning '>Upcoming Hearings</h2>
                        </Col>
                        <Col md={6} className="align-self-center text-right">
                            {/* <ModalToggler 
                                title="Edit A Hearing"
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                defaultIsDeadline={true}
                            >
                                <button className='btn btn-outline-warning  btn-sm'>
                                    Add Admin Hearing <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler> */}
                            <span className='pl-4'>

                            <ModalToggler 
                                title="Edit A Hearing"
                                findTasks={fetchItems} 
                                component={Edit} 
                                mod={'bk'}
                                metadata={{ stage: stage }}
                                modalIndex={2000}
                                defaultIsDeadline={true}
                                defaultCategory={'bk_hearing'}
                                defaultLockTitles={true}
                                itemTypes={[
                                    {
                                        label: "341 Hearings",
                                        options: [
                                            // { value: '341 Original Hearing', label: '341 Original Hearing', checklist: ['Call Trustee', 'Contact Dave', 'Is Mayonnaise An Instruction', 'test'] },
                                            { value: '341 Original Hearing', label: '341 Original Hearing' },
                                            { value: '341 Control Hearing', label: '341 Control Hearing' },
                                            { value: '341 Adjourned Hearing', label: '341 Adjourned Hearing' },
                                        ]
                                    },
                                    {
                                        label: "Confirmation Hearings",
                                        options: [
                                            { value: 'Confirmation Original Hearing', label: 'Confirmation Original Hearing' },
                                            { value: 'Confirmation Control Hearing', label: 'Confirmation Control Hearing' },
                                            { value: 'Confirmation Adjourned Hearing', label: 'Confirmation Adjourned Hearing' },
                                        ]
                                    },
                                    {
                                        label: "Show Cause Hearing",
                                        options: [
                                            { value: 'Show Cause', label: 'Show Cause' },
                                        ]
                                    },
                                    {
                                        label: "Motions",
                                        options: [
                                            { value: 'Motion', label: 'Motion' },
                                        ]
                                    },
                                    {
                                        label: "Modifications",
                                        options: [
                                            { value: 'Modification', label: 'Modification' },
                                        ]
                                    },
                                    {
                                        label: "Discharge",
                                        options: [
                                            { value: 'Discharge', label: 'Discharge' },
                                        ]
                                    },
                                    {
                                        label: "Adversary",
                                        options: [
                                            { value: 'Adversary', label: 'Adversary' },
                                        ]
                                    },
                                    {
                                        label: "Objections",
                                        options: [
                                            { value: 'Objection to Proof of Claim', label: 'Objection to Proof of Claim' },
                                        ]
                                    },

                                    
                                ]}
                            >
                                <button className='btn btn-outline-success  btn-sm'>
                                    Add Hearing <i className="fas fa-plus ml-2" />
                                </button>
                            </ModalToggler>
                            </span>

                        </Col>
                    </Row>
                )}
            />



        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


