// "_id": 1,
// "company": 1,
// "division": 1,
// "matter": 1,
// "name": 1,
// "assigned_to": 1,
// "due_date": 1,
// "priority": 1,
// "finished_on": 1,
// "created_at": 1,

import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'

import { getUrlParameter } from "utils/urls";

import api from 'api';

import Circle from 'components/markup/loading/Circle'

import formatText from 'utils/formatText'

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, viewing_user}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hasDueDate, setDueDate ] = useState(true)
    const [ onlyMe, setOnlyMe ] = useState(false)
    const [ sort, setSort ] = useState('due_date +1')
    const [ priority, setPriority ] = useState('all')
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetPriority = useCallback((_priority) => {
        setPriority(_priority)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    const onSetSort = useCallback((_sort) => {
        setSort(_sort)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    const onSetOnlyMe = useCallback((_onlyMe) => {
        setOnlyMe(_onlyMe)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
  
    const onSetHasDueDate = useCallback((_hasDueDate) => {
        setDueDate(_hasDueDate)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        const type = getUrlParameter('type')

        // params.filter.division = selected_division._id
        params.filter.finished_at = 0
        if(selected_division._id) params.filter.division = selected_division._id

        if(type === 'overdue') {
            params.filter.due_date = { $gt: 0, $lte: Math.floor(new Date() / 1000)}
        } else {
            if(hasDueDate) {
                params.filter.due_date = { $gt: 0 }
            } else {
                params.filter.due_date = 0
            }
        }

        if(onlyMe) params.filter.onlyMe = true;
        if(priority !== 'all') params.filter.priority = priority;


        if(sort === 'due_date +1') {
            params.sort = { due_date: 1 }
        } else if(sort === 'due_date -1') {
            params.sort = { due_date: -1 }
        } else if(sort === 'next_follow_up_at -1') {
            params.sort = { next_follow_up_at: -1 }
        } else if(sort === 'next_follow_up_at +1') {
            params.sort = { next_follow_up_at: 1 }
        } else if(sort === 'priority -1') {
            params.sort = { priority: -1, due_date: 1 }
        } else if(sort === 'priority +1') {
            params.sort = { priority: 1, due_date: 1 }
        } else if(sort === 'alphabetical') {
            params.sort = { name: 1 }
        }

        const query = await api.outstanding_items.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Task',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/matters/${row.matter}?tab=Items&docsTab=Outstanding`} className="text-dark font-weight-bold">{row.name}</Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "last_comment",
            text: 'Comment',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.last_comment ? '' : (
                    <span>
                        {formatText(row.last_comment).stripHTML(100)}
                        {' - '}
                        <b className="text-info">{row.last_comment_by ? <ObjectFinder collection="users" _id={row.last_comment_by}/> : 'System'}</b>
                    </span>
                )
            )
        },
        {
            dataField: "assigned_to",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.assigned_to ? '' :
                row.assigned_to.map((c, i) => (
                    <span>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c} /></span>
                ))
            )
        },
        {
            dataField: "priority",
            text: 'Priority',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(row.priority === 1) return <Badge color="danger">Highest</Badge>
                if(row.priority === 2) return <Badge color="warning">High</Badge>
                if(row.priority === 3) return <Badge color="info">Default</Badge>
                if(row.priority === 4) return <Badge color="purple">Low</Badge>
                if(row.priority === 5) return <Badge color="success">Lowest</Badge>
            }
        },
        {
            dataField: "due_date",
            text: 'Due Date',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(!row.due_date) return '-';

                const color = row.due_date < now ? 'text-danger font-weight-bold' : ''
                return <span className={color}>{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span>
            }
        },
        {
            dataField: "next_follow_up_at",
            text: 'Follow Up Date',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(!row.next_follow_up_at) return '-';

                const color = row.next_follow_up_at < now ? 'text-danger font-weight-bold' : ''
                return <span className={color}>{moment.unix(row.next_follow_up_at).format('MM/DD/YYYY h:mm A')}</span>
            }
        },
        // {
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div><Link to={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></Link></div>
                </div>
            )
        },
        
        // {
        //     dataField: "due_date",
        //     text: 'due',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div>{!row.due_date ? '-' : row.due_date < Math.floor(new Date() / 1000) ? <span className="text-danger">{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span> : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</div>
        //             </div>
        //         )
        //     )
        // },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/matters/${row.matter}?tab=Items&docsTab=Outstanding`,
                                },
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/matters/${row.matter}?tab=Items&docsTab=Outstanding`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = getUrlParameter('type') === 'overdue' ? 'Overdue Items' :  'All Items'
    const description = getUrlParameter('type') === 'overdue' ? 'All Items with a due date before the current time' : 'Showing all Items assigned to you.'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={3}
            actionComponent={(
                <div>

                    {/* {getUrlParameter('type') === 'overdue' ? '' : (
                        <UncontrolledDropdown>
                            <DropdownToggle color="outline-info">
                                {
                                    hasDueDate ? 'Has Due Date' : 'No Due Date Set'
                                }
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={e => onSetHasDueDate(true)}>Has Due Date</DropdownItem>
                                <DropdownItem onClick={e => onSetHasDueDate(false)}>No Due Date Set</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )} */}

                    <UncontrolledDropdown>
                        <DropdownToggle color="outline-info">
                            {
                                priority === 'all' ? 'All Priority' : 
                                priority === 1 ? 'Highest Priority' : 
                                priority === 2 ? 'High Priority' : 
                                priority === 3 ? 'Normal Priority' : 
                                priority === 4 ? 'Low Priority' : 
                                priority === 5 ? 'Lowest Priority' : 
                                ''
                            }
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => onSetPriority('all')}>All</DropdownItem>
                            <DropdownItem onClick={e => onSetPriority(1)}>Highest</DropdownItem>
                            <DropdownItem onClick={e => onSetPriority(2)}>High</DropdownItem>
                            <DropdownItem onClick={e => onSetPriority(3)}>Normal</DropdownItem>
                            <DropdownItem onClick={e => onSetPriority(4)}>Low</DropdownItem>
                            <DropdownItem onClick={e => onSetPriority(5)}>Lowest</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown>
                        <DropdownToggle color="outline-success">
                            {
                                onlyMe ? 'Only Assigned To Me' : 'Assigned To Me'
                            }
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => onSetOnlyMe(true)}>Only Assigned To Me</DropdownItem>
                            <DropdownItem onClick={e => onSetOnlyMe(false)}>Assigned To Me</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                     <UncontrolledDropdown>
                        <DropdownToggle color="outline-warning">
                            {
                                sort === 'due_date +1' ? 'Sort By Due Date Ascending' : 
                                sort === 'due_date -1' ? 'Sort By Due Date Descending' :
                                sort === 'next_follow_up_at +1' ? 'Next Follow Up At Ascending' :
                                sort === 'next_follow_up_at -1' ? 'Next Follow Up At Descending' : 
                                sort === 'priority -1' ? 'Highest Priority' :
                                sort === 'priority +1' ? 'Lowest Priority' : 
                                sort === 'alphabetical' ? 'Alphabetical' : 

                                ''
                            }
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => onSetSort('due_date -1')}>Sort By Due Date Ascending</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('due_date +1')}>Sort By Due Date Descending</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('next_follow_up_at -1')}>Next Follow Up At Ascending</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('next_follow_up_at +1')}>Next Follow Up At Descending</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('priority -1')}>Highest Priority</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('priority +1')}>Lowest Priority</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('alphabetical')}>Alphabetical</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                </div>
            )}
        />

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search By Item Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
