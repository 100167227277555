import React from 'react';
import { FormGroup, Input, Row, Col } from "reactstrap";
import States from 'components/markup/inputs/States'

const ContactsUpdate = ({onInputChange,  obj}) => {

    const line_1 = obj.address && obj.address.address_line_1 ? obj.address.address_line_1 : ''
    const line_2 = obj.address && obj.address.address_line_2 ? obj.address.address_line_2 : ''
    const city = obj.address && obj.address.city ? obj.address.city : ''
    const state = obj.address && obj.address.state ? obj.address.state : ''
    const postal_code = obj.address && obj.address.postal_code ? obj.address.postal_code : ''
    const county = obj.address && obj.address.county ? obj.address.county : ''

    return (
        <div>
        
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 1</label>
                        <Input 
                            type="text"
                            value={line_1}
                            onChange={e => onInputChange('address', {
                                address_line_1: e.target.value,
                                address_line_2: line_2,
                                city: city,
                                state: state,
                                postal_code: postal_code,
                                county: county,
                            })}
                        />
                    </FormGroup>    
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 2</label>
                        <Input 
                            type="text"
                            value={line_2}
                            onChange={e => onInputChange('address', {
                                address_line_1: line_1,
                                address_line_2: e.target.value,
                                city: city,
                                state: state,
                                postal_code: postal_code,
                                county: county,
                            })}
                        />
                    </FormGroup>    
                </Col>
            </Row>

            <Row>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">City</label>
                        <Input 
                            type="text"
                            value={city}
                            onChange={e => onInputChange('address', {
                                address_line_1: line_1,
                                address_line_2: line_2,
                                city: e.target.value,
                                state: state,
                                postal_code: postal_code,
                                county: county,
                            })}
                        />
                    </FormGroup>    
                </Col>
                <Col lg={4}>
                    <States 
                        title="State"
                        value={state}
                        onChange={(val) => onInputChange('address', {
                            address_line_1: line_1,
                            address_line_2: line_2,
                            city: city,
                            state: val,
                            postal_code: postal_code,
                            county: county,
                        })}
                    /> 
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">Zip</label>
                        <Input 
                            type="text"
                            value={postal_code}
                            onChange={e => onInputChange('address', {
                                address_line_1: line_1,
                                address_line_2: line_2,
                                city: city,
                                state: state,
                                postal_code: e.target.value,
                                county: county,
                            })}
                        />
                    </FormGroup>    
                </Col>
            </Row>

            <FormGroup>
                <label className="form-control-label">County</label>
                <Input 
                    type="text"
                    value={county}
                    onChange={e => onInputChange('address', {
                        address_line_1: line_1,
                        address_line_2: line_2,
                        city: city,
                        state: state,
                        postal_code: postal_code,
                        county: e.target.value,
                    })}
                />
            </FormGroup>    
        </div>
    )

}

export default ContactsUpdate;