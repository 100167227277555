import React from 'react';
import { FormGroup, Input, Row, Col } from "reactstrap";

const ContactsUpdate = ({onInputChange, obj, showMileage}) => {

    return (
        <div>
        
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Year</label>
                        <Input 
                            type="text"
                            value={obj.year}
                            onChange={e => onInputChange('year', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Make</label>
                        <Input 
                            type="text"
                            value={obj.make}
                            onChange={e => onInputChange('make', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Model</label>
                        <Input 
                            type="text"
                            value={obj.model}
                            onChange={e => onInputChange('model', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
                {showMileage ? (
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Mileage</label>
                            <Input 
                                type="text"
                                value={obj.mileage}
                                onChange={e => onInputChange('mileage', e.target.value)}
                            />
                        </FormGroup>    
                    </Col>
                ) : ''}
                
            </Row>

        </div>
    )

}

export default ContactsUpdate;