

import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup, Input, Row, Col } from 'reactstrap';


const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Trustee Info</CardTitle>
                </CardHeader>

                <CardFooter> 
                    <FormGroup>
                        <label className='form-control-label'>Trustee Name</label>
                        <Input 
                            type="text"
                            value={data.trustee}
                            onChange={e => onInputChange('trustee', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

                <CardFooter> 
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Online Payment Link</label>
                                <Input 
                                    type="text"
                                    value={data.trustee_payment_link}
                                    onChange={e => onInputChange('trustee_payment_link', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Website</label>
                                <Input 
                                    type="text"
                                    value={data.trustee_website}
                                    onChange={e => onInputChange('trustee_website', e.target.value)}
                                />
                            </FormGroup>
                           
                        </Col>
                    </Row>

                   
                    
                    <FormGroup>
                        <label className='form-control-label'>In Person Payment Address (*Enter As 1 Line)</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 50}}
                            value={data.trustee_payment_address}
                            onChange={e => onInputChange('trustee_payment_address', e.target.value)}
                        />
                    </FormGroup>

                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


