import { useState, useCallback, useEffect } from 'react';

import Navigation from './Navigation'

import Circle from 'components/markup/loading/Circle'

import ScheduleAB from './ScheduleAB'
import ScheduleC from './ScheduleC'
import ScheduleDEF from './ScheduleDEF'
import ScheduleG from './ScheduleG'
import ScheduleH from './ScheduleH'

import api from 'api'

import { toast } from 'react-toastify'

const schedules = [
    'Property - A/B',
    'Exemptions - C',
    'Debts - D/E/F',
    'Contract / Leases - G',
    'Codebtors - H',
    'Income - I',
    'Expenses - J',
]

const ModalCustomFields = () => {

    const [config, setConfig] = useState(null)
    const [schedule, setSchedule] = useState(schedules[0])

    const onSetSchedule = useCallback((s) => {
        setSchedule(s)
    }, [])

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.config()
        if(result.data) return setConfig(result.data)

        toast.error(`Could not load schedules, please refresh your page.`)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!config) return <Circle />

    return (

        <div>

            <h2 className='text-warning mb-4'>Real And Personal Property</h2>

            <Navigation 
                schedule={schedule}
                onSetSchedule={onSetSchedule}
                schedules={schedules}
            />

            {schedule === schedules[0] ? (
                <ScheduleAB config={config} />
            ) : schedule === schedules[1] ? (
                <ScheduleC config={config} />
            ) : schedule === schedules[2] ? (
                <ScheduleDEF config={config} />
            ) : schedule === schedules[3] ? (
                <ScheduleG config={config} />
            ) : schedule === schedules[4] ? (
                <ScheduleH config={config} />
            ) : ''}

        </div>
    )
}


export default ModalCustomFields