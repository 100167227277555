
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";


import { formatCurrency } from 'utils/currency';

const ModalDivisionOverride = ({obj, onInputChange}) => {

    const unknown = `amount_secured_unknown`;
    const liens = `amount_secured_linked_to_liens`;

    return (

        <div>

            <FormGroup>
                <label className="form-control-label">Amount Of Secured Claim: {obj.amount_secured ? <span className='text-info font-weight-bold'>{formatCurrency(obj.amount_secured)}</span> : '$0.00'}</label>
                <Input 
                    disabled={obj[unknown]|| obj[liens] ? true : false}
                    type="number"
                    value={obj.amount_secured}
                    onChange={e => onInputChange('amount_secured', parseFloat(e.target.value))}
                />
            </FormGroup>   

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-amount-secured-unknown`}
                        type="checkbox"
                        checked={obj[unknown] || false}
                        onChange={() => onInputChange(unknown, !obj[unknown])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-amount-secured-unknown`}>
                        Amount Is Unknown
                    </label>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-amount-secured-liens`}
                        type="checkbox"
                        checked={obj[liens] || false}
                        onChange={() => onInputChange(liens, !obj[liens])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-amount-secured-liens`}>
                        Linked To Liens
                    </label>
                </div>
            </FormGroup>

            <hr className='my-' />

            <p className='text-sm border rounded text-center p-3 bg-secondary'>
                <i className="fas fa-info-circle text-info mr-2" /> Net Equity:{' '}
                {formatCurrency(obj.current_value ? obj.current_value - obj.amount_secured : obj.current_value)}
            </p>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);