import { FormGroup, Input } from "reactstrap";

const ContactsUpdate = ({onInputChange, obj}) => {

    return (
        <div>
        
            <FormGroup>
                <label className="form-control-label">Name Of Institution / Entity / Property</label>
                <Input 
                    type="text"
                    value={obj.institution_name}
                    onChange={e => onInputChange('institution_name', e.target.value)}
                />
            </FormGroup>    

        </div>
    )

}

export default ContactsUpdate;