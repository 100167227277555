/*

SCHEMA

account_type

address: {
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
    county,
}

amount_secured
amount_secured_unknown (bool)
amount_secured_linked_to_liens (bool)

beneficiary

description

institution_name

nature_of_interest

ownership (d1, d2, d1 & 2)
ownership_percent

property_type
property_type_is_investment
property_type_is_timeshare


current_value
current_value_type
current_value_partial_interest_percent

year
make
model
mileage

*/



import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Row } from "reactstrap";
import { toast } from 'react-toastify'

import api from 'api'

import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';
import ConfirmationModal from 'components/functional/modals/Confirmation';

import InputAccountType from './Inputs/AccountType';
import InputAddress from './Inputs/Address';
import InputAmountSecured from './Inputs/AmountSecured';
import InputBeneficiary from './Inputs/Beneficiary';
import InputDescription from './Inputs/Description';
import InputInstitutionName from './Inputs/InstitutionName';
import InputNatureOfInterest from './Inputs/NatureOfInterest';
import InputOwnership from './Inputs/Ownership';
import InputOwnershipPercent from './Inputs/OwnershipPercent';
import InputPropertyType from './Inputs/PropertyType';
import InputValue from './Inputs/Value';
import InputVehicle from './Inputs/Vehicle';

const baseState = {
    category: 1,
    account_type: '',
    address: {
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        county: '',
    },
    amount_secured: 0,
    amount_secured_unknown: false,
    amount_secured_linked_to_liens: false,
    beneficiary: '',
    description: '',
    institution_name: '',
    nature_of_interest: '',
    ownership: 'd1',
    ownership_percent: 100,
    property_type: '',
    property_type_is_investment: false,
    property_type_is_timeshare: false,
    current_value: 0,
    current_value_type: 'known',
    current_value_partial_interest_percent: 0,
    year: '',
    make: '',
    model: '',
    mileage: '',
}

const ModalDivisionOverride = ({showModal, toggleModal, onSuccess, property_id, matter, config}) => {

    const [property, setProperty] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [err, setErr] = useState(null)

    const cat = property ? property.category : '';

    const onInputChange = useCallback((field, val) => {
        const _property = JSON.parse(JSON.stringify(property));
        _property[field] = val;
        setProperty(_property)
    }, [property])

    const inputs = {
        address: (key) => (
            <InputAddress 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        natureOfInterest: (key) => (
            <InputNatureOfInterest 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        description: (key) => (
            <InputDescription 
                key={key}
                title={"Asset Description"}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        ownership: (key) => (
            <InputOwnership 
                key={key}
                title={"Who Owns This Property?"}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        propertyType: (key) => (
            <InputPropertyType 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        value: (key) => (
            <InputValue 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        amountSecured: (key) => (
            <InputAmountSecured 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        vehicle: (key) => (
            <InputVehicle 
                key={key}
                obj={property}
                onInputChange={onInputChange}
                showMileage={cat === 2}
            />
        ),
        accountType: (key) => (
            <InputAccountType 
                key={key}
                obj={property}
                onInputChange={onInputChange}
                options={[
                    { value: 'checking', label: 'Checking' },
                    { value: 'savings', label: 'Savings' },
                    { value: 'certificate_of_deposit', label: 'Certificate Of Deposit' },
                    { value: 'credit_union', label: 'Credit Union' },
                    { value: 'brokerage', label: 'Brokerage' },
                ]}
            />
        ),
        accountTypeRetirement: (key) => (
            <InputAccountType 
                title="Type of Account: (IRA, ERISA, Keogh, 401(k), 403(b), Pension or Profit-Sharing plans)"
                key={key}
                obj={property}
                onInputChange={onInputChange}
                options={[
                    { value: 'ira', label: 'IRA' },
                    { value: 'erisa', label: 'ERISA' },
                    { value: 'keogh', label: 'KEOGH' },
                    { value: '401k', label: '401(k)' },
                    { value: '403b', label: '403(b)' },
                    { value: '457', label: '457' },
                    { value: 'pension', label: 'Pension' },
                    { value: 'profit_sharing_plan', label: 'Profit Sharing Plan' },
                    { value: 'thrift_saving', label: 'Thrift Saving' },
                ]}
            />
        ),
        accountTypeSecurity: (key) => (
            <InputAccountType 
                title="Type of service pre-paid: (Rent, Public, Utilities (Electric, Gas, Heat, Water), Phone, Telecommunications)"
                key={key}
                obj={property}
                onInputChange={onInputChange}
                options={[
                    { value: 'electric', label: 'Electric' },
                    { value: 'furniture', label: 'Furniture' },
                    { value: 'gas', label: 'Gas' },
                    { value: 'heating_oil', label: 'Heating Oil' },
                    { value: 'rent_prepaid', label: 'Rent Prepaid' },
                    { value: 'rental_deposit', label: 'Rental Deposit' },
                    { value: 'telephone', label: 'Telephone' },
                    { value: 'utility', label: 'Utility' },
                    { value: 'water', label: 'Water' },
                ]}
            />
        ),
        accountTypeTaxRefund: (key) => (
            <InputAccountType 
                title="Type Of Tax Refund"
                key={key}
                obj={property}
                onInputChange={onInputChange}
                options={[
                    { value: 'federal', label: 'Federal' },
                    { value: 'state', label: 'State' },
                    { value: 'local', label: 'Local' },
                ]}
            />
        ),
        accountTypeFamilySupport: (key) => (
            <InputAccountType 
                title="Type of Support (Alimony, Child Support, Maintenance, Divorce Settlement, Property Settlement)"
                key={key}
                obj={property}
                onInputChange={onInputChange}
                options={[
                    { value: 'alimony', label: 'Alimony' },
                    { value: 'child_support', label: 'Child Support' },
                    { value: 'divorce_settlement', label: 'Divorce Settlement' },
                    { value: 'maintenance', label: 'Maintenance' },
                    { value: 'property_settlement', label: 'Property Settlement' },
                ]}
            />
        ),
        institutionName: (key) => (
            <InputInstitutionName 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        ownershipPercent: (key) => (
            <InputOwnershipPercent 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
        beneficiary: (key) => (
            <InputBeneficiary 
                key={key}
                obj={property}
                onInputChange={onInputChange}
            />
        ),
    }

    const renderCategoryFields = (categories) => (
        <Row>
            <Col md={6}>
                {categories[0].map((c, i) => c ? inputs[c](i) : <hr key={i} />) }
            </Col>

            <Col md={6}>
                {categories[1].map((c, i) => c ? inputs[c](i) : <hr key={i} />) }
            </Col>
        </Row>
    )

    const onSave = useCallback(async () => {

        const params = {
            ...property,
            matter: matter._id,
            amount_secured: property.amount_secured ? parseFloat(property.amount_secured) : 0,
            current_value: property.current_value ? parseFloat(property.current_value) : 0,
            current_value_partial_interest_percent: property.current_value_partial_interest_percent ? parseFloat(property.current_value_partial_interest_percent) : 0,
            ownership_percent: property.ownership_percent ? parseFloat(property.ownership_percent) : 0,
        }

        let result;

        if(property_id) {
            result = await api.modules.bk.properties.update(property._id, params)
        } else {
            result = await api.modules.bk.properties.create(params)
        }

        

        if(!result.success) return toast.error(`Something went wrong updating this creditor, please refresh your page`)
        
        toast.success(`Creditor updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [matter._id, onSuccess, property, property_id, toggleModal])
  
    const onDelete = useCallback(async () => {


        const result = await api.modules.bk.properties.delete(property._id)

        if(!result.success) return toast.error(`Something went wrong deleting this property, please refresh your page`)
        
        toast.success(`Property deleted successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [property, onSuccess, toggleModal])

    const fetchData = useCallback(async () => {
        if(showModal) {
            if(property_id) {
                const result = await api.modules.bk.properties.findById(property_id);
                console.log(result)
                if(!result.success) return setErr(true);
                setProperty(result.data)
            } else {
                setProperty(baseState)
            }
           
        } else {
            setErr(false)
        }
    }, [property_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Property / Asset</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {property ? (
                 <div className="modal-body border-bottom bg-secondary">
                    <ReactSelect
                        title="Category"
                        formGroup={true}
                        onChange={(obj) => onInputChange('category', obj.value)}
                        formatOptionLabel={({ value, type, label }, { context, selectValue }) => (
                            <span>
                                <b 
                                    className={context === 'menu' && cat === value ? 'text-white' : 'text-dark'} 
                                    style={{display: 'inline-block', width: 135}}
                                >
                                    {type}
                                </b>{' '}
                                {label}
                            </span>
                        )}
                        options={config.properties.categories.map(c => { return { type: c.type, label: c.name, value: c.id } })}
                        value={property.category}
                    />    
                </div>
            ) : ''}
           

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this property / asset, please refresh your page.</div>
                </div>
            ) : !property ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div className="modal-body">
                    {
                        cat === 1 ? 
                            renderCategoryFields([ 
                                [ 'institutionName', 'address', '', 'natureOfInterest', 'description', '', 'ownership' ], 
                                ['propertyType', '', 'value', '', 'amountSecured'] 
                            ])
                        : cat === 2 ? (
                            renderCategoryFields([ 
                                ['vehicle', '', 'description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 3 ? (
                            renderCategoryFields([ 
                                ['vehicle', '', 'description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 4 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 5 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 6 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 7 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 8 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 9 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        )  : cat === 10 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 11 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 12 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 13 ? (
                            renderCategoryFields([ 
                                ['description', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 14 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'accountType', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 15 ? (
                            renderCategoryFields([ 
                                ['institutionName', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 16 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'ownershipPercent', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        )  : cat === 17 ? (
                            renderCategoryFields([ 
                                ['institutionName', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 18 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'accountTypeRetirement', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 19 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'accountTypeSecurity', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 20 ? (
                            renderCategoryFields([ 
                                ['institutionName', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 21 ? (
                            renderCategoryFields([ 
                                ['institutionName', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 22 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 23 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 24 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 25 ? (
                            renderCategoryFields([ 
                                ['description', 'accountTypeTaxRefund', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 26 ? (
                            renderCategoryFields([ 
                                ['description', 'accountTypeFamilySupport', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 27 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 28 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'beneficiary', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 29 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 30 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 31 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 32 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 33 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 34 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 35 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 36 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 37 ? (
                            renderCategoryFields([ 
                                ['institutionName', 'ownershipPercent', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 38 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 39 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 40 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 41 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 42 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 43 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 44 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        ) : cat === 45 ? (
                            renderCategoryFields([ 
                                ['description', '', 'ownership' ], 
                                ['value', '', 'amountSecured'] 
                            ])
                        )  : ''
                    }
                </div>
            )}

          

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && property ? (
                    <div>
                        {property._id ? (
                            <button className="btn btn-outline-danger" onClick={() => setShowDelete(true)}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : ''}
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save
                        </button>
                    </div>
                ) : ''}
            </div>

            <ConfirmationModal 
                showModal={showDelete}
                toggleModal={() => setShowDelete(false)}
                title="Delete Creditor"
                body={<span>Are you sure you sure to delete this property.</span>}
                onConfirmation={onDelete}
                zIndex={2000}
            />


        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);