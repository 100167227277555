import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import Circle from 'components/markup/loading/Circle';
import ConfirmationModal from 'components/functional/modals/Confirmation';
import States from 'components/markup/inputs/States'

import api from 'api';

import { toast } from 'react-toastify';

const baseState = {
    bk_creditors                  : [],
    bk_contracts                  : [],

    name                          : '',
    comment                       : '',
    
    exclude_from_mailing_matrix   : false,

    address_line_1                : '',
    address_line_2                : '',
    city                          : '',
    state                         : '',
    postal_code                   : '',
}

const ModalCodebtorsEdit = ({showModal, toggleModal, config, onSuccess, codebtor_id, matter}) => {

    const [err, setErr] = useState(false)
    const [codebtor, setCodebtor] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    
    const [allCreditors, setAllCreditors] = useState(null)
    const [allContracts, setAllContracts] = useState(null)

    const onInputChange = useCallback((field, val) => {
        const _codebtor = JSON.parse(JSON.stringify(codebtor));
        _codebtor[field] = val;
        setCodebtor(_codebtor)
    }, [codebtor])

    const onCreditorChange = useCallback((_id) => {
        const _codebtor = JSON.parse(JSON.stringify(codebtor));
        const included = _codebtor.bk_creditors.includes(_id);

        if(included) {
            _codebtor.bk_creditors = _codebtor.bk_creditors.filter(c => !c.includes(_id))
        } else {
            _codebtor.bk_creditors.push(_id)
        }
        setCodebtor(_codebtor)
    }, [codebtor])
    
    const onContractChange = useCallback((_id) => {
        const _codebtor = JSON.parse(JSON.stringify(codebtor));
        const included = _codebtor.bk_contracts.includes(_id);

        if(included) {
            _codebtor.bk_contracts = _codebtor.bk_contracts.filter(c => !c.includes(_id))
        } else {
            _codebtor.bk_contracts.push(_id)
        }
        setCodebtor(_codebtor)
    }, [codebtor])
  
    const onSave = useCallback(async () => {

        const params = {
            ...codebtor,
            matter: matter._id,
        }

        let result;

        if(codebtor_id) {
            result = await api.modules.bk.codebtors.update(codebtor._id, params)
        } else {
            result = await api.modules.bk.codebtors.create(params)
        }

        if(!result.success) return toast.error(`Something went wrong updating this codebtor, please refresh your page`)
        
        toast.success(`Codebtor updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [codebtor, codebtor_id, matter._id, onSuccess, toggleModal])
  
    const onDelete = useCallback(async () => {
        if(!codebtor.name) return toast.info(`A name must be set before updating a codebtor`);

        const result = await api.modules.bk.codebtors.delete(codebtor._id)

        if(!result.success) return toast.error(`Something went wrong deleting this codebtor, please refresh your page`)
        
        toast.success(`Codebtor deleted successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [codebtor, onSuccess, toggleModal])

    const fetchLinkedDebt = useCallback(async () => {
        const values = await Promise.all([
            api.modules.bk.creditors.findByMatter(matter._id),
            api.modules.bk.contracts.findByMatter(matter._id),
        ])

        setAllCreditors(values[0].data ? values[0].data : [])
        setAllContracts(values[1].data ? values[1].data : [])
    }, [matter._id])

    const fetchData = useCallback(async () => {
        if(showModal) {
            if(codebtor_id) {
                const result = await api.modules.bk.codebtors.findById(codebtor_id);
                if(!result.success) return setErr(true);
                setCodebtor(result.data)                
            } else {
                setCodebtor(baseState)
            }
           
        } else {
            setErr(false)
        }
    }, [codebtor_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if(showModal) {
            fetchLinkedDebt()
        }
    }, [fetchLinkedDebt, showModal])

    return (

        <>

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Codebtor</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this codebtor, please refresh your page.</div>
                </div>
            ) : !codebtor ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                    <div className="modal-body border-bottom">

                        <FormGroup>
                            <label className='form-control-label'>Codebtor Name</label>
                            <Input 
                                type="text"
                                value={codebtor.name}
                                onChange={(e) => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                    </div>

                    <div className="modal-body border-bottom bg-secondary">

                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={codebtor.address_line_1}
                                        onChange={e => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={codebtor.address_line_2}
                                        onChange={e => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={codebtor.city}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <States 
                                    title="State"
                                    value={codebtor.state}
                                    onChange={obj => onInputChange('state', obj.value)}
                                /> 
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={codebtor.postal_code}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>

                    </div>

                    <div className="modal-body  border-bottom">
                        <Row>
                            <Col md={6}>

                                <FormGroup>
                                    <label className='form-control-label'>Comment</label>
                                    <Input 
                                        type="textarea"
                                        style={{minHeight: 120}}
                                        value={codebtor.comment}
                                        onChange={(e) => onInputChange('comment', e.target.value)}
                                    />
                                </FormGroup>

                            </Col>

                            <Col xs={6}>

                                <FormGroup className='mt-5'>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            id="archk-exclude_from_mailing_matrix"
                                            type="checkbox"
                                            checked={codebtor.exclude_from_mailing_matrix}
                                            onChange={() => onInputChange('exclude_from_mailing_matrix', !codebtor.exclude_from_mailing_matrix)}
                                            />
                                        <label className="custom-control-label" htmlFor="archk-exclude_from_mailing_matrix">
                                            Exclude from Mailing Matrix
                                        </label>
                                    </div>
                                </FormGroup>

                            </Col>
                        </Row>
                    </div>

                    {allCreditors ? (
                        <div className='modal-body'>
                            <p className='mb-0'>Link Creditors From Schedules D, E, & F</p>
                            <div className='table-responsive border'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th style={{width: 30}}></th>
                                            <th>Creditor</th>
                                            <th>Schedule</th>
                                            <th className='text-right'>Account Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allCreditors.length ? allCreditors.map(c => (
                                            <tr>
                                                <td>
                                                    <FormGroup style={{position: 'relative', top: 5, left: 7}}>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                id={`archk-creditors-${c._id}`}
                                                                type="checkbox"
                                                                checked={codebtor.bk_creditors.includes(c._id)}
                                                                onChange={() => onCreditorChange(c._id)}
                                                                />
                                                            <label className="custom-control-label" htmlFor={`archk-creditors-${c._id}`}>
                                                                
                                                            </label>
                                                        </div>
                                                    </FormGroup>
                                                </td>
                                                <td>{c.name}</td>
                                                <td>{c.schedule}</td>
                                                <td className='text-right'>{c.account_identifier}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Creditors Found</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : ''}
                    
                    {allContracts ? (
                        <div className='modal-body'>
                            <p className='mb-0'>Link Contracts / Leases From Schedule G</p>
                            <div className='table-responsive border'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th style={{width: 30}}></th>
                                            <th>Party</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allContracts.length ? allContracts.map(c => (
                                            <tr>
                                                <td>
                                                    <FormGroup style={{position: 'relative', top: 5, left: 7}}>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                className="custom-control-input"
                                                                id={`archk-contracts-${c._id}`}
                                                                type="checkbox"
                                                                checked={codebtor.bk_contracts.includes(c._id)}
                                                                onChange={() => onContractChange(c._id)}
                                                                />
                                                            <label className="custom-control-label" htmlFor={`archk-contracts-${c._id}`}>
                                                                
                                                            </label>
                                                        </div>
                                                    </FormGroup>
                                                </td>
                                                <td>{c.other_party}</td>
                                                <td>{c.description}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Contracts / Leases Found</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : ''}

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && codebtor ? (
                    <div>
                        {codebtor._id ? (
                            <button className="btn btn-outline-danger" onClick={() => setShowDelete(true)}>
                                <i className="fas fa-trash mr-2" /> Delete
                            </button>
                        ) : ''}
                        <button className="btn btn-success" onClick={onSave}>
                            <i className="fas fa-save mr-2" /> Save
                        </button>
                    </div>
                ) : ''}
            </div>

        </Modal>

        <ConfirmationModal 
            showModal={showDelete}
            toggleModal={() => setShowDelete(false)}
            title="Delete Codebtor"
            body={<span>Are you sure you sure to delete this codebtor.</span>}
            onConfirmation={onDelete}
            zIndex={2000}
        />

        </>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalCodebtorsEdit);